// Generated by ReScript, PLEASE EDIT WITH CARE

import * as H2 from "../../../../../../styleguide/components/Heading/H2.res.js";
import * as EnzuPricingMobile from "./components/EnzuPricingMobile.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as EnzuPricingDesktop from "./components/EnzuPricingDesktop.res.js";
import * as EnzuBareMetalPricingScss from "./EnzuBareMetalPricing.scss";

var css = EnzuBareMetalPricingScss;

function EnzuBareMetalPricing(props) {
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(H2.make, {
                      className: css.title,
                      children: "Enzu Bare Metal Pricing and Configurations "
                    }),
                JsxRuntime.jsx(EnzuPricingDesktop.make, {}),
                JsxRuntime.jsx(EnzuPricingMobile.make, {})
              ],
              className: css.wrapper
            });
}

var make = EnzuBareMetalPricing;

export {
  css ,
  make ,
}
/* css Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as HorizontalScroll from "../HorizontalScroll/HorizontalScroll.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as JumbotronProviderIconsScss from "./JumbotronProviderIcons.scss";
import LogoNflPng from "Images/jumbotron-logos/logo-nfl.png";
import LogoVmwarePng from "Images/jumbotron-logos/logo-vmware.png";
import LogoCoinbasePng from "Images/jumbotron-logos/logo-coinbase.png";
import AwsLogoWhitePng from "Images/jumbotron-logos/aws-logo-white.png";
import LogoUnivisionPng from "Images/jumbotron-logos/logo-univision.png";
import LogoDellWhitePng from "Images/jumbotron-logos/logo-dell-white.png";
import AzureLogoWhitePng from "Images/jumbotron-logos/azure-logo-white.png";
import LogoIheartmediaPng from "Images/jumbotron-logos/logo-iheartmedia.png";
import LogoDockerWhitePng from "Images/jumbotron-logos/logo-docker-white.png";
import LogoTencentGamesPng from "Images/jumbotron-logos/logo-tencent-games.png";
import LogoKubernetesWhitePng from "Images/jumbotron-logos/logo-kubernetes-white.png";
import LogoSupermicroWhitePng from "Images/jumbotron-logos/logo-supermicro-white.png";
import GoogleCloudLogoWhitePng from "Images/jumbotron-logos/google-cloud-logo-white.png";
import AlibabaCloudLogoWhitePng from "Images/jumbotron-logos/alibaba-cloud-logo-white.png";
import TencentCloudLogoWhitePng from "Images/jumbotron-logos/tencent-cloud-logo-white.png";

var css = JumbotronProviderIconsScss;

function JumbotronProviderIcons(props) {
  var tmp;
  switch (props.context) {
    case "Zenlayer" :
        tmp = JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx("img", {
                      className: css.icon,
                      alt: "AWS",
                      src: AwsLogoWhitePng
                    }),
                JsxRuntime.jsx("img", {
                      className: css.icon,
                      alt: "Google",
                      src: GoogleCloudLogoWhitePng
                    }),
                JsxRuntime.jsx("img", {
                      className: css.icon,
                      alt: "Azure",
                      src: AzureLogoWhitePng
                    }),
                JsxRuntime.jsx("img", {
                      className: css.icon,
                      alt: "Alibaba",
                      src: AlibabaCloudLogoWhitePng
                    }),
                JsxRuntime.jsx("img", {
                      className: css.icon,
                      alt: "Tencent",
                      src: TencentCloudLogoWhitePng
                    })
              ]
            });
        break;
    case "Enzu" :
        tmp = JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx("img", {
                      className: css.icon,
                      alt: "AWS",
                      src: AwsLogoWhitePng
                    }),
                JsxRuntime.jsx("img", {
                      className: css.icon,
                      alt: "Google",
                      src: GoogleCloudLogoWhitePng
                    }),
                JsxRuntime.jsx("img", {
                      className: css.icon,
                      alt: "Supermicro",
                      src: LogoSupermicroWhitePng
                    }),
                JsxRuntime.jsx("img", {
                      className: css.icon,
                      alt: "Dell",
                      src: LogoDellWhitePng
                    }),
                JsxRuntime.jsx("img", {
                      className: css.icon,
                      alt: "Docker",
                      src: LogoDockerWhitePng
                    }),
                JsxRuntime.jsx("img", {
                      className: css.icon,
                      alt: "Kubernetes",
                      src: LogoKubernetesWhitePng
                    })
              ]
            });
        break;
    case "Hivelocity" :
        tmp = JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx("img", {
                      className: css.iconSmall,
                      alt: "Coinbase",
                      src: LogoCoinbasePng
                    }),
                JsxRuntime.jsx("img", {
                      className: css.iconSmall,
                      alt: "NFL",
                      src: LogoNflPng
                    }),
                JsxRuntime.jsx("img", {
                      className: css.iconSmall,
                      alt: "iHeartMedia",
                      src: LogoIheartmediaPng
                    }),
                JsxRuntime.jsx("img", {
                      className: css.iconSmall,
                      alt: "Tencent Games",
                      src: LogoTencentGamesPng
                    }),
                JsxRuntime.jsx("img", {
                      className: css.iconSmall,
                      alt: "Univision",
                      src: LogoUnivisionPng
                    }),
                JsxRuntime.jsx("img", {
                      className: css.iconSmall,
                      alt: "Vmware",
                      src: LogoVmwarePng
                    })
              ]
            });
        break;
    
  }
  return JsxRuntime.jsx(HorizontalScroll.make, {
              children: JsxRuntime.jsx("div", {
                    children: tmp,
                    className: css.wrapper
                  })
            });
}

var make = JumbotronProviderIcons;

export {
  css ,
  make ,
}
/* css Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as User from "../../../../models/User.res.js";
import * as EnzuCaseStudy from "./components/case-study/EnzuCaseStudy.res.js";
import * as JumbotronEnzu from "./components/jumbotron/JumbotronEnzu.res.js";
import * as ExclusiveServices from "../../../../styleguide/components/ExclusiveServices/ExclusiveServices.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as EnzuBareMetalScss from "./EnzuBareMetal.scss";
import * as EnzuBareMetalPricing from "./components/pricing/EnzuBareMetalPricing.res.js";
import * as EnzuServicesOverview from "./components/services-overview/EnzuServicesOverview.res.js";
import * as ResourcesSidebarSection from "../../../resources/common/sidebar-section/ResourcesSidebarSection.res.js";
import * as RequestEstimateEnzuBareMetal from "./components/request-estimate/RequestEstimateEnzuBareMetal.res.js";
import EnzuHeaderLeftSvg from "./assets/enzu-header-left.svg";
import EnzuHeaderRightSvg from "./assets/enzu-header-right.svg";
import HeaderDotsSvg from "../../common/assets/header-dots.svg";
import * as ComponentsImportsLoadableJsx from "Loadable/components.imports-loadable.jsx";

var css = EnzuBareMetalScss;

var headerDotsSvg = HeaderDotsSvg;

var bareMetalHeaderLeftSvg = EnzuHeaderLeftSvg;

var bareMetalHeaderRightSvg = EnzuHeaderRightSvg;

function EnzuBareMetal$default(props) {
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx("div", {
                            children: JsxRuntime.jsx(JumbotronEnzu.make, {}),
                            className: css.backgroundImage,
                            style: {
                              backgroundImage: "url(" + bareMetalHeaderLeftSvg + "), url(" + bareMetalHeaderRightSvg + ")"
                            }
                          }),
                      className: css.jumbotronSection
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx("div", {
                            children: JsxRuntime.jsx(EnzuBareMetalPricing.make, {}),
                            className: css.pricingAndConfigurationsSectionContainer
                          }),
                      className: css.pricingAndConfigurationsSection
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(RequestEstimateEnzuBareMetal.make, {}),
                      className: css.requestEstimateBareMetalSection
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx(EnzuServicesOverview.make, {}),
                              className: css.detailsContent
                            }),
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx(ExclusiveServices.make, {
                                      title: "Exclusive Services",
                                      services: [
                                        {
                                          service: "Optimal Gaming Experiences",
                                          description: "Unleash your gaming potential with Enzu Bare Metal Cloud - the perfect choice for cost savings and scalable online gaming. Say goodbye to lag and hello to a seamless gaming experience, all thanks to our dedicated servers. Game on!"
                                        },
                                        {
                                          service: "Rev-Up Your Online Sales",
                                          description: "Experience swift website transactions throughout the year, with the trusted option of storing your databases safely. And to deal with seasonal traffic, leverage our virtual machines for high-speed performance. Make your Ecommerce dreams come true with us!"
                                        },
                                        {
                                          service: "Get Regulation-ready",
                                          description: "Ever been worried about complying with industry-regulated data privacy rules for your healthcare or finance business? Relax. Bare Metal Cloud offers seamless control of data sharing and storage, making regulation compliance a breeze."
                                        },
                                        {
                                          service: "Unleash Big Data Analytics",
                                          description: "Don't let the sheer volume and speed of your data overwhelm your virtual cloud platform. Our Enzu Bare Metal Cloud is the ideal solution for scaling up your big data analysis to new heights."
                                        },
                                        {
                                          service: "Lightning-Fast Provisioning",
                                          description: "Say goodbye to long wait times! With our lightning-fast automated provisioning platform, your dedicated server will be up and running in mere minutes. No more days or weeks of downtime. Get started now and experience the speed of rapid provisioning."
                                        },
                                        {
                                          service: "Simplified solutions",
                                          description: "Imagine a world where enterprise computing is just as easy as personal computing. That's our vision. And the good news is, our team of tech experts are here to make your deployment as seamless and pain-free as possible. You decide how much assistance you need - we're here to make it happen. Let's make your computing dreams a reality."
                                        },
                                        {
                                          service: "Ultimate Flexibility",
                                          description: "Enjoy the dependability of a dedicated server, alongside the flexibility of the cloud. Experience localized service delivery with all the advantages of cloud computing. Discover the ultimate solution for your business needs today."
                                        }
                                      ]
                                    }),
                                JsxRuntime.jsx("div", {
                                      children: JsxRuntime.jsx(ResourcesSidebarSection.make, {
                                            title: "Resources",
                                            categorySlug: "buyers-guides",
                                            resources: props.buyersGuides,
                                            query: "enzu"
                                          }),
                                      className: css.sticky
                                    })
                              ],
                              className: css.detailsSidebar
                            })
                      ],
                      className: css.detailsSection
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx("div", {
                            children: JsxRuntime.jsx(ComponentsImportsLoadableJsx.EnzuBareMetalMap, {}),
                            className: css.map
                          }),
                      className: css.caseStudySection
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(EnzuCaseStudy.make, {
                            enzuWhitePaper: props.enzuWhitePaper,
                            userLoginStatus: User.LoginStatus.fromBool(props.isUserLoggedIn),
                            setUserData: props.setUserData
                          }),
                      className: css.caseStudySection
                    })
              ],
              className: css.wrapper
            });
}

var $$default = EnzuBareMetal$default;

export {
  css ,
  headerDotsSvg ,
  bareMetalHeaderLeftSvg ,
  bareMetalHeaderRightSvg ,
  $$default as default,
}
/* css Not a pure module */

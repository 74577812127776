// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Cloudinary from "../../../../libs/Cloudinary.res.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ExclusiveServicesScss from "../ExclusiveServices.scss";

var css = ExclusiveServicesScss;

var downTick = Cloudinary.imageUrl("f_auto/odyueovkug5xyu6wviwo");

var upTick = Cloudinary.imageUrl("f_auto/f6piw9kzvl4hxdluxnbo");

function ExclusiveService(props) {
  var descriptionWithLink = props.descriptionWithLink;
  var description = props.description;
  var match = React.useState(function () {
        return false;
      });
  var setIsExpanded = match[1];
  var isExpanded = match[0];
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsxs("a", {
                            children: [
                              JsxRuntime.jsx("div", {
                                    children: props.service
                                  }),
                              JsxRuntime.jsx("div", {
                                    children: JsxRuntime.jsx("img", {
                                          src: isExpanded ? upTick : downTick
                                        }),
                                    className: css.icon
                                  })
                            ],
                            className: css.link,
                            onClick: (function (param) {
                                setIsExpanded(function (state) {
                                      return !state;
                                    });
                              })
                          }),
                      className: css.serviceBlock
                    }),
                isExpanded ? JsxRuntime.jsx("div", {
                        children: description !== undefined ? (
                            descriptionWithLink !== undefined ? null : description
                          ) : (
                            descriptionWithLink !== undefined ? Caml_option.valFromOption(descriptionWithLink) : null
                          ),
                        className: css.description
                      }) : null
              ],
              className: css.service
            });
}

var make = ExclusiveService;

export {
  css ,
  downTick ,
  upTick ,
  make ,
}
/*  Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../../../../../libs/ID.res.js";
import * as Url from "../../../../../../utils/Url.res.js";
import * as React from "react";
import * as Button from "../../../../../../styleguide/components/Button/Button.res.js";
import * as $$Promise from "../../../../../../bindings/Promise.res.js";
import * as Cloudinary from "../../../../../../libs/Cloudinary.res.js";
import * as SentryLogger from "../../../../../../loggers/SentryLogger.res.js";
import * as Routes_Resource from "../../../../../../routes/common/Routes_Resource.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as EnzuCaseStudyBanner from "./components/banner/EnzuCaseStudyBanner.res.js";
import * as SignInModalContainer from "../../../../../../styleguide/modals/SignInModal/SignInModalContainer.res.js";
import * as NotifyBareMetalCaseStudyDownloadRequest from "../../../../../../api/requests/NotifyBareMetalCaseStudyDownloadRequest.res.js";

var enzu = "enzu";

var initialState = {
  signInModal: "Hidden"
};

function reducer(_state, action) {
  return {
          signInModal: action._0
        };
}

function EnzuCaseStudy(props) {
  var userLoginStatus = props.userLoginStatus;
  var enzuWhitePaper = props.enzuWhitePaper;
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var match$1 = match[0].signInModal;
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(EnzuCaseStudyBanner.make, {
                      title: "Enzu Bare Metal White Paper",
                      description: "Explore the confluence of automation and IT infrastructure advances that have created a massive opportunity for businesses.",
                      ctaComponent: enzuWhitePaper !== undefined ? JsxRuntime.jsx(Button.make, {
                              size: "MD",
                              color: "Teal",
                              onClick: (function (param) {
                                  if (userLoginStatus === "LoggedIn") {
                                    return $$Promise.wait(NotifyBareMetalCaseStudyDownloadRequest.exec(ID.toString(enzuWhitePaper.id), enzu), (function (x) {
                                                  if (x.TAG !== "Ok") {
                                                    return SentryLogger.error1({
                                                                rootModule: "EnzuCaseStudy",
                                                                subModulePath: /* [] */0,
                                                                value: "make",
                                                                fullPath: "EnzuCaseStudy.make"
                                                              }, "EnzuCaseStudy" + "::CaseStudyBareMetal::notifyDownload::Error", [
                                                                "Error",
                                                                x._0
                                                              ]);
                                                  }
                                                  Url.visitBlank(enzuWhitePaper.privateDownload);
                                                  Url.visit(Routes_Resource.downloadThanks(enzuWhitePaper.title));
                                                }));
                                  } else {
                                    return dispatch({
                                                TAG: "ToggleSignInModal",
                                                _0: "Shown"
                                              });
                                  }
                                }),
                              children: "Download White Paper"
                            }) : null,
                      testimonialText: "\"IT automation is developing all around us from our talking refrigerators to self-driving cars. Businesses are rushing to understand and reap the benefits from automation.\"",
                      testimonialAuthorName: "Dustin Young",
                      testimonialAuthorRole: "EVP of Global Channels",
                      testimonialAuthorImage: Cloudinary.imageUrl("v1682611362/static/dustin_young_enzu")
                    }),
                match$1 === "Shown" ? JsxRuntime.jsx(SignInModalContainer.make, {
                        close: (function () {
                            dispatch({
                                  TAG: "ToggleSignInModal",
                                  _0: "Hidden"
                                });
                          }),
                        callback: (function () {
                            
                          }),
                        setUserData: props.setUserData,
                        subTitle: "Sign In to download the white paper"
                      }) : null
              ]
            });
}

var make = EnzuCaseStudy;

export {
  enzu ,
  initialState ,
  reducer ,
  make ,
}
/* ID Not a pure module */

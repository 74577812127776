// Generated by ReScript, PLEASE EDIT WITH CARE

import * as H4 from "../../../../styleguide/components/Heading/H4.res.js";
import * as Picture from "../../../../styleguide/forms/Picture/Picture.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Routes_Resource from "../../../../routes/common/Routes_Resource.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ResourcesSidebarSectionScss from "./ResourcesSidebarSection.scss";

var css = ResourcesSidebarSectionScss;

function ResourcesSidebarSection(props) {
  var query = props.query;
  var categorySlug = props.categorySlug;
  var title = props.title;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(H4.make, {
                      children: title
                    }),
                JsxRuntime.jsx("div", {
                      children: Belt_Array.mapWithIndex(props.resources, (function (index, resource) {
                              return JsxRuntime.jsxs("div", {
                                          children: [
                                            JsxRuntime.jsx("div", {
                                                  children: JsxRuntime.jsx(Picture.make, {
                                                        src: resource.resourceImage,
                                                        large: [
                                                          40,
                                                          40
                                                        ],
                                                        imageClassName: css.resourceImage
                                                      }),
                                                  className: css.imageContainer
                                                }),
                                            JsxRuntime.jsx("div", {
                                                  children: JsxRuntime.jsx("a", {
                                                        children: resource.title,
                                                        href: Routes_Resource.show(resource.slug, resource.categorySlug)
                                                      }),
                                                  className: css.resourceTitle
                                                })
                                          ],
                                          className: css.resource
                                        }, "resource--" + String(index));
                            })),
                      className: css.resources
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx("a", {
                            children: "View All " + title,
                            href: query !== undefined ? Routes_Resource.category(categorySlug) + ("?query=" + Belt_Option.getExn(Caml_option.valFromOption(query))) : Routes_Resource.category(categorySlug)
                          }),
                      className: css.resourcesLink
                    })
              ],
              className: css.wrapper
            });
}

var make = ResourcesSidebarSection;

export {
  css ,
  make ,
}
/* css Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import CheckSvg from "../assets/check.svg";
import * as EnzuBareMetalPricingScss from "../EnzuBareMetalPricing.scss";

var css = EnzuBareMetalPricingScss;

var check = CheckSvg;

function EnzuPricingDesktop(props) {
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsxs("div", {
                    children: [
                      JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsx("div", {
                                    children: "Small",
                                    className: css.headerCell
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx("div", {
                                            children: "Medium"
                                          }),
                                      JsxRuntime.jsx("div", {
                                            children: "Container Optimized",
                                            className: css.subheading
                                          })
                                    ],
                                    className: css.headerCell
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx("div", {
                                            children: "Large"
                                          }),
                                      JsxRuntime.jsx("div", {
                                            children: "Container Optimized",
                                            className: css.subheading
                                          })
                                    ],
                                    className: css.headerCell
                                  }),
                              JsxRuntime.jsx("div", {
                                    children: "GPU/AI",
                                    className: css.headerCell
                                  })
                            ],
                            className: Cx.cx([
                                  css.row,
                                  css.headerRow
                                ])
                          }),
                      JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx("div", {
                                            children: "CPUs",
                                            className: css.meta
                                          }),
                                      JsxRuntime.jsx("div", {
                                            children: "E-2336"
                                          }),
                                      JsxRuntime.jsx("div", {
                                            children: "(6 Cores - 12 Threads)"
                                          })
                                    ],
                                    className: css.cell
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx("div", {
                                            children: "CPUs",
                                            className: css.meta
                                          }),
                                      JsxRuntime.jsx("div", {
                                            children: "Dual Intel Scalable Silver 4116"
                                          }),
                                      JsxRuntime.jsx("div", {
                                            children: "(24 Cores - 48 Threads)"
                                          })
                                    ],
                                    className: Cx.cx([
                                          css.cell,
                                          css.cellGray
                                        ])
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx("div", {
                                            children: "CPUs",
                                            className: css.meta
                                          }),
                                      JsxRuntime.jsx("div", {
                                            children: "Dual Intel Scalable Gold 6326"
                                          }),
                                      JsxRuntime.jsx("div", {
                                            children: "(32 Cores - 64 Threads)"
                                          })
                                    ],
                                    className: css.cell
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx("div", {
                                            children: "CPUs",
                                            className: css.meta
                                          }),
                                      JsxRuntime.jsx("div", {
                                            children: "Intel Scalable or AMD Epyc"
                                          }),
                                      JsxRuntime.jsx("div", {
                                            children: "A100/H100/RTX GPU"
                                          })
                                    ],
                                    className: Cx.cx([
                                          css.cell,
                                          css.cellGray
                                        ])
                                  })
                            ],
                            className: css.row
                          }),
                      JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx("div", {
                                            children: "Memory",
                                            className: css.meta
                                          }),
                                      JsxRuntime.jsx("div", {
                                            children: "32g to 128GB RAM"
                                          })
                                    ],
                                    className: css.cell
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx("div", {
                                            children: "Memory",
                                            className: css.meta
                                          }),
                                      JsxRuntime.jsx("div", {
                                            children: "64g to 768GB RAM"
                                          })
                                    ],
                                    className: Cx.cx([
                                          css.cell,
                                          css.cellGray
                                        ])
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx("div", {
                                            children: "Memory",
                                            className: css.meta
                                          }),
                                      JsxRuntime.jsx("div", {
                                            children: "128g to 2048GB RAM"
                                          })
                                    ],
                                    className: css.cell
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx("div", {
                                            children: "Memory",
                                            className: css.meta
                                          }),
                                      JsxRuntime.jsx("div", {
                                            children: "128g to 6144GB RAM"
                                          })
                                    ],
                                    className: Cx.cx([
                                          css.cell,
                                          css.cellGray
                                        ])
                                  })
                            ],
                            className: css.row
                          }),
                      JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx("div", {
                                            children: "Storage",
                                            className: css.meta
                                          }),
                                      JsxRuntime.jsx("div", {
                                            children: "(2) 512GB NVMe"
                                          })
                                    ],
                                    className: css.cell
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx("div", {
                                            children: "Storage",
                                            className: css.meta
                                          }),
                                      JsxRuntime.jsx("div", {
                                            children: "(2) 960GB SATA SSD"
                                          })
                                    ],
                                    className: Cx.cx([
                                          css.cell,
                                          css.cellGray
                                        ])
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx("div", {
                                            children: "Storage",
                                            className: css.meta
                                          }),
                                      JsxRuntime.jsx("div", {
                                            children: "(2) 512GB NVMe"
                                          }),
                                      JsxRuntime.jsx("div", {
                                            children: "(2) 960GB NVMe"
                                          })
                                    ],
                                    className: css.cell
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx("div", {
                                            children: "Storage",
                                            className: css.meta
                                          }),
                                      JsxRuntime.jsx("div", {
                                            children: "(2) 512GB NVMe"
                                          }),
                                      JsxRuntime.jsx("div", {
                                            children: "(2) 960GB NVMe"
                                          })
                                    ],
                                    className: Cx.cx([
                                          css.cell,
                                          css.cellGray
                                        ])
                                  })
                            ],
                            className: css.row
                          }),
                      JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx("div", {
                                            children: "Network",
                                            className: css.meta
                                          }),
                                      JsxRuntime.jsx("div", {
                                            children: "(2) 1Gbps Ports Burstable to 2Gbps"
                                          })
                                    ],
                                    className: css.cell
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx("div", {
                                            children: "Network",
                                            className: css.meta
                                          }),
                                      JsxRuntime.jsx("div", {
                                            children: "(2) 1Gbps Ports Burstable to 2Gbps"
                                          })
                                    ],
                                    className: Cx.cx([
                                          css.cell,
                                          css.cellGray
                                        ])
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx("div", {
                                            children: "Network",
                                            className: css.meta
                                          }),
                                      JsxRuntime.jsx("div", {
                                            children: "(2) 10Gbps Ports Burstable to 20Gbps"
                                          })
                                    ],
                                    className: css.cell
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx("div", {
                                            children: "Network",
                                            className: css.meta
                                          }),
                                      JsxRuntime.jsx("div", {
                                            children: "(2) 10Gbps Ports Burstable to 20Gbps"
                                          })
                                    ],
                                    className: Cx.cx([
                                          css.cell,
                                          css.cellGray
                                        ])
                                  })
                            ],
                            className: css.row
                          }),
                      JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx("div", {
                                            children: "DDoS Protection",
                                            className: css.meta
                                          }),
                                      JsxRuntime.jsx("img", {
                                            src: check
                                          })
                                    ],
                                    className: css.cell
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx("div", {
                                            children: "DDoS Protection",
                                            className: css.meta
                                          }),
                                      JsxRuntime.jsx("img", {
                                            src: check
                                          })
                                    ],
                                    className: Cx.cx([
                                          css.cell,
                                          css.cellGray
                                        ])
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx("div", {
                                            children: "DDoS Protection",
                                            className: css.meta
                                          }),
                                      JsxRuntime.jsx("img", {
                                            src: check
                                          })
                                    ],
                                    className: css.cell
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx("div", {
                                            children: "DDoS Protection",
                                            className: css.meta
                                          }),
                                      JsxRuntime.jsx("img", {
                                            src: check
                                          })
                                    ],
                                    className: Cx.cx([
                                          css.cell,
                                          css.cellGray
                                        ])
                                  })
                            ],
                            className: css.row
                          }),
                      JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx("div", {
                                            children: "Full-Service Portal",
                                            className: css.meta
                                          }),
                                      JsxRuntime.jsx("img", {
                                            src: check
                                          })
                                    ],
                                    className: css.cell
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx("div", {
                                            children: "Full-Service Portal",
                                            className: css.meta
                                          }),
                                      JsxRuntime.jsx("img", {
                                            src: check
                                          })
                                    ],
                                    className: Cx.cx([
                                          css.cell,
                                          css.cellGray
                                        ])
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx("div", {
                                            children: "Full-Service Portal",
                                            className: css.meta
                                          }),
                                      JsxRuntime.jsx("img", {
                                            src: check
                                          })
                                    ],
                                    className: css.cell
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx("div", {
                                            children: "Full-Service Portal",
                                            className: css.meta
                                          }),
                                      JsxRuntime.jsx("img", {
                                            src: check
                                          })
                                    ],
                                    className: Cx.cx([
                                          css.cell,
                                          css.cellGray
                                        ])
                                  })
                            ],
                            className: css.row
                          }),
                      JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsx("div", {
                                    children: JsxRuntime.jsxs("div", {
                                          children: [
                                            "from",
                                            JsxRuntime.jsx("span", {
                                                  children: "$169"
                                                }),
                                            "/month"
                                          ]
                                        }),
                                    className: Cx.cx([
                                          css.cell,
                                          css.pricingCell
                                        ])
                                  }),
                              JsxRuntime.jsx("div", {
                                    children: JsxRuntime.jsxs("div", {
                                          children: [
                                            "from",
                                            JsxRuntime.jsx("span", {
                                                  children: "$349"
                                                }),
                                            "/month"
                                          ]
                                        }),
                                    className: Cx.cx([
                                          css.cell,
                                          css.pricingCell,
                                          css.cellGray
                                        ])
                                  }),
                              JsxRuntime.jsx("div", {
                                    children: JsxRuntime.jsxs("div", {
                                          children: [
                                            "from",
                                            JsxRuntime.jsx("span", {
                                                  children: "$699"
                                                }),
                                            "/month"
                                          ]
                                        }),
                                    className: Cx.cx([
                                          css.cell,
                                          css.pricingCell
                                        ])
                                  }),
                              JsxRuntime.jsx("div", {
                                    children: JsxRuntime.jsxs("div", {
                                          children: [
                                            "from",
                                            JsxRuntime.jsx("span", {
                                                  children: "$999"
                                                }),
                                            "/month"
                                          ]
                                        }),
                                    className: Cx.cx([
                                          css.cell,
                                          css.pricingCell,
                                          css.cellGray
                                        ])
                                  })
                            ],
                            className: Cx.cx([
                                  css.row,
                                  css.pricingRow
                                ])
                          })
                    ],
                    className: css.pricingTable
                  }),
              className: css.desktop
            });
}

var make = EnzuPricingDesktop;

export {
  css ,
  check ,
  make ,
}
/* css Not a pure module */

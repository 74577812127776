// Generated by ReScript, PLEASE EDIT WITH CARE

import * as H4 from "../Heading/H4.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ExclusiveService from "./components/ExclusiveService.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ExclusiveServicesScss from "./ExclusiveServices.scss";

var css = ExclusiveServicesScss;

function ExclusiveServices(props) {
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(H4.make, {
                            children: props.title
                          }),
                      className: css.title
                    }),
                JsxRuntime.jsx("div", {
                      children: Belt_Array.mapWithIndex(props.services, (function (index, service) {
                              return JsxRuntime.jsx("div", {
                                          children: JsxRuntime.jsx(ExclusiveService.make, {
                                                service: service.service,
                                                description: service.description
                                              })
                                        }, "service--" + String(index));
                            })),
                      className: css.services
                    })
              ],
              className: css.wrapper
            });
}

var make = ExclusiveServices;

export {
  css ,
  make ,
}
/* css Not a pure module */

import * as React from 'react';
import ReduxProvider from 'ReduxProvider';
import { connect } from 'react-redux';
import { setUserData } from '../../../../../bundles/Client/common/actions/userActions';
import { fromJs } from '../../../../../rescript/models/User.res.js';

import EnzuBareMetal from './EnzuBareMetal.res.js';

const mapStateToProps = state => ({
  isUserLoggedIn: !!state.user.id,
  userData: fromJs(state.user),
});

const mapDispatchToProps = dispatch => ({
  setUserData: userData => {
    dispatch(setUserData(userData));
  },
});

const EnzuBareMetalContainer = connect(mapStateToProps, mapDispatchToProps)(EnzuBareMetal);

export default props => (
  <ReduxProvider {...props}>
    <EnzuBareMetalContainer enzuWhitePaper={props.enzuWhitePaper} buyersGuides={props.buyersGuides} />
  </ReduxProvider>
);

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as HorizontalScroll from "../../../../../../../styleguide/components/HorizontalScroll/HorizontalScroll.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as EnzuPricingColumnMobile from "./pricing-column-mobile/EnzuPricingColumnMobile.res.js";
import * as EnzuBareMetalPricingScss from "../EnzuBareMetalPricing.scss";

var css = EnzuBareMetalPricingScss;

function EnzuPricingMobile(props) {
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsxs(HorizontalScroll.make, {
                    children: [
                      JsxRuntime.jsx(EnzuPricingColumnMobile.make, {
                            heading: "Small",
                            cpusA: "E-2336",
                            cpusB: "(6 Cores - 12 Threads)",
                            memory: "32GB to 128GB RAM",
                            storageA: "(2) 512GB NVMe",
                            network: "(2) 1Gbps Ports Burstable to 2Gbps",
                            price: "169"
                          }),
                      JsxRuntime.jsx(EnzuPricingColumnMobile.make, {
                            heading: "Medium",
                            subheading: "Container Optimized",
                            cpusA: "Dual Intel Scalable Silver 4116",
                            cpusB: "(24 Cores - 48 Threads)",
                            memory: "64GB to 768GB RAM",
                            storageA: "(2) 960GB SATA SSD",
                            network: "(2) 1Gbps Ports Burstable to 2Gbps",
                            price: "349"
                          }),
                      JsxRuntime.jsx(EnzuPricingColumnMobile.make, {
                            heading: "Large",
                            subheading: "Container Optimized",
                            cpusA: "Dual Intel Scalable Gold 6326",
                            cpusB: "(32 Cores - 64 Threads)",
                            memory: "128GB to 2048GB RAM",
                            storageA: "(2) 512GB NVMe",
                            storageB: "(2) 960GB NVMe",
                            network: "(2) 10Gbps Ports Burstable to 20Gbps",
                            price: "699"
                          }),
                      JsxRuntime.jsx(EnzuPricingColumnMobile.make, {
                            heading: "GPU/AI",
                            cpusA: "Intel Scalable or AMD Epyc",
                            cpusB: "A100/H100/RTX GPU",
                            memory: "128GB to 6144GB RAM",
                            storageA: "(2) 512GB NVMe",
                            storageB: "(2) 960GB NVMe",
                            network: "(2) 10Gbps Ports Burstable to 20Gbps",
                            price: "999"
                          }),
                      JsxRuntime.jsx("div", {
                            className: css.spacer
                          })
                    ],
                    className: css.horizontalScrollRow
                  }),
              className: css.mobile
            });
}

var make = EnzuPricingMobile;

export {
  css ,
  make ,
}
/* css Not a pure module */
